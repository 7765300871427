import React, { Component } from 'react';
import './App.css';

import { PICTURES } from './pictures';
import { SHAPES } from './shapes';

import 'fabric';

import { DpistickerDemo } from './dpisticker/DpistickerDemo';

class App extends Component {
  state = {
    picture: PICTURES[0],
    pictureScale: 1,
    pictureAngle: 0,
    shape: SHAPES[7],
    preview: PICTURES[0],
    result: PICTURES[0],
    outline: PICTURES[0],
  };

  render() {
    return (
      <div className="App">
        <div className="demo-container">
          <div className="demo-pictures">
            {PICTURES.map((picture) => (
              <img
                key={picture.id}
                src={picture.uri}
                alt={picture.id}
                className={picture.id === this.state.picture.id ? 'demo-picture-selected' : ''}
                onClick={() => this.setState({ picture: picture })}
              />
            ))}
          </div>
          <div className="demo-shapes">
            {SHAPES.map((shape) => (
              <img
                key={shape.id}
                src={shape.uri.indexOf('<svg') === 0 ? 'data:image/svg+xml;utf8,' + shape.uri : shape.uri}
                alt={shape.id}
                className={shape.id === this.state.shape.id ? 'demo-shape-selected' : ''}
                onClick={() => this.setState({ shape: shape })}
              />
            ))}
          </div>
          <div className="dpisticker-controls">
            <input
              className="dpisticker-controls-scale"
              type="range"
              list="dpisticker-controls-scale-tickmarks"
              min="10"
              max="190"
              step="1"
              defaultValue={this.state.pictureScale * 100}
              style={{ display: 'block', width: '100%' }}
              onInput={(e) => this.setState({ pictureScale: e.target.valueAsNumber / 100 })}
            />
            <datalist id="dpisticker-controls-scale-tickmarks">
              <option value="50" label="50%" />
              <option value="100" label="100%" />
              <option value="150" label="150%" />
            </datalist>

            <input
              className="dpisticker-controls-angle"
              type="range"
              list="dpisticker-controls-angle-tickmarks"
              min="-180"
              max="180"
              step="1"
              defaultValue={this.state.pictureAngle}
              style={{ display: 'block', width: '100%' }}
              onInput={(e) => this.setState({ pictureAngle: e.target.valueAsNumber })}
            />
            <datalist id="dpisticker-controls-angle-tickmarks">
              <option value="-180" label="-180°" />
              <option value="-135" />
              <option value="-90" label="-90°" />
              <option value="-45" />
              <option value="0" label="0°" />
              <option value="45" />
              <option value="90" label="90°" />
              <option value="135" />
              <option value="180" label="180°" />
            </datalist>
          </div>
          <div className="demo-editor">
            <DpistickerDemo
              picture={this.state.picture.uri}
              pictureScale={this.state.pictureScale}
              pictureAngle={this.state.pictureAngle}
              shape={this.state.shape.uri}
              onChange={({ preview, result, outline }) =>
                this.setState({
                  preview: { uri: preview },
                  result: { uri: result },
                  outline: { uri: outline },
                })
              }
            />
          </div>
          <div className="demo-preview">
            <img src={this.state.preview.uri} alt="preview" />
            <img src={this.state.result.uri} alt="result" />
            <img src={this.state.outline.uri} alt="outline" />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
