export const SHAPES = [
  {
    id: 'simple_square',
    uri: require('./assets/shapes/simple_square.svg'),
  },
  {
    id: 'simple_circle',
    uri: require('./assets/shapes/simple_circle.svg'),
  },
  // {
  //   id: 'simple_circle_alt',
  //   uri: `
  //   <svg width="400" height="400" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <path d="M200 400C310.457 400 400 310.457 400 200C400 89.543 310.457 0 200 0C89.543 0 0 89.543 0 200C0 310.457 89.543 400 200 400Z" fill="#BBBBBB"/>
  //   </svg>
  //   `.trim(),
  // },
  {
    id: 'simple_triangle',
    uri: require('./assets/shapes/simple_triangle.svg'),
  },
  {
    id: 'simple_star',
    uri: require('./assets/shapes/simple_star.svg'),
  },
  {
    id: 'template_square',
    uri: require('./assets/shapes/template_square.svg'),
  },
  {
    id: 'template_rectangle_rounded_corner',
    uri: require('./assets/shapes/template_rectangle_rounded_corner.svg'),
  },
  {
    id: 'template_circle',
    uri: require('./assets/shapes/template_circle.svg'),
  },
  {
    id: 'template_oval',
    uri: require('./assets/shapes/template_oval.svg'),
  },
];
