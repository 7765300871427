// import { fabric } from 'fabric';
var fabric = window.fabric;

window.StickerEditor = class StickerEditor {
  static CLONE_PROPERTIES = ['id'];

  picture = null;
  pictureScale = null;
  shape = null;

  outlineId = 'outline';
  midlineId = 'midline';
  inlineId = 'inline';

  outlineStrokeColor = '#00AEEF';
  midlineStrokeColor = '#EC008C';

  $container = null;
  $canvasElement = null;
  $canvas = null;
  $picture = null;

  $shape = null;
  $shapeClipPath = null;
  $shapeOverlay = null;
  $cover = null;
  $coverOverlay = null;

  $result$canvasContainer = null;
  $result$canvasElement = null;
  $result$canvas = null;
  $result$picture = null;
  $result$shape = null;
  $result$shapeClipPath = null;
  $result$shapeOverlay = null;

  changeCallback = () => {};

  constructor($container, picture, shape) {
    this.$container = $container;
    this.picture = picture;
    this.shape = shape.indexOf('<svg') === 0 ? 'data:image/svg+xml;utf8,' + shape : shape;
  }

  loadImage(uri) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.onabort = reject;
      img.oncancel = reject;
      img.crossOrigin = 'anonymous';
      img.src = uri;
    });
  }

  registerChangeCallback(callback) {
    this.changeCallback = callback;
  }

  unregisterChangeCallback() {
    this.changeCallback = () => {};
  }

  initialize() {
    // console.log('initialize');

    this.$canvasElement = document.createElement('canvas');
    this.$container.append(this.$canvasElement);

    this.$canvas = new fabric.Canvas(this.$canvasElement);
    this.$canvas.selection = false;
    this.$canvas.preserveObjectStacking = true;

    // this.$controlsContainer = document.createElement('div');
    // this.$controlsContainer.className = 'dpisticker-controls';

    // this.$controlsContainer.innerHTML = `
    //   <input class="dpisticker-controls-scale" type="range" list="dpisticker-controls-scale-tickmarks" min="10" max="190" step="1" value="100" style="display: block; width: 100%;">
    //   <datalist id="dpisticker-controls-scale-tickmarks">
    //     <option value="50" label="50%">
    //     <option value="100" label="100%">
    //     <option value="150" label="150%">
    //   </datalist>

    //   <input class="dpisticker-controls-angle" type="range" list="dpisticker-controls-angle-tickmarks" min="-180" max="180" step="1" value="0" style="display: block; width: 100%;">
    //   <datalist id="dpisticker-controls-angle-tickmarks">
    //     <option value="-180" label="-180°">
    //     <option value="-135">
    //     <option value="-90" label="-90°">
    //     <option value="-45">
    //     <option value="0" label="0°">
    //     <option value="45">
    //     <option value="90" label="90°">
    //     <option value="135">
    //     <option value="180" label="180°">
    //   </datalist>
    // `;

    // this.$scaleInput = this.$controlsContainer.querySelector('.dpisticker-controls-scale');
    // this.$scaleInput.addEventListener('input', (e) => this.scalePicture(e.target.valueAsNumber / 100));

    // this.$angleInput = this.$controlsContainer.querySelector('.dpisticker-controls-angle');
    // this.$angleInput.addEventListener('input', (e) => this.rotatePicture(e.target.valueAsNumber));

    // this.$container.append(this.$controlsContainer);

    this.$result$canvasContainer = document.createElement('div');
    this.$result$canvasContainer.style.backgroundColor = 'white';
    this.$result$canvasContainer.style.display = 'none';
    this.$container.append(this.$result$canvasContainer);
    this.$result$canvasElement = document.createElement('canvas');
    this.$result$canvasContainer.append(this.$result$canvasElement);
    this.$result$canvas = new fabric.Canvas(this.$result$canvasElement);
    this.$result$canvas.selection = false;

    return this.setPicture(this.picture).then(() => this.setShape(this.shape));
  }

  setPicture(picture) {
    // console.log('setPicture', picture);

    this.picture = picture;

    if (this.$picture) {
      this.$canvas.remove(this.$picture);
      this.$picture = null;
    }

    if (this.$result$picture) {
      this.$result$canvas.remove(this.$result$picture);
      this.$result$picture = null;
    }

    return this.loadImage(this.picture).then((img) => {
      const width = this.$container.offsetWidth;
      const height = (img.height / img.width) * this.$container.offsetWidth;

      this.$canvas.setDimensions({
        width,
        height,
      });

      this.pictureScale = width / img.width;

      this.$picture = new fabric.Image(img, {
        scaleX: this.pictureScale,
        scaleY: this.pictureScale,
        originX: 'center',
        originY: 'center',
        left: width / 2,
        top: height / 2,
        selectable: true,
        lockUniScaling: true,
        hasBorders: false,
        hasControls: false,
      });

      this.$canvas.add(this.$picture);

      this.$picture.on('moved', () => this.render());

      this.$result$canvas.setDimensions({
        width,
        height,
      });

      return new Promise((resolve) => {
        this.$picture.clone(($result$picture) => {
          this.$result$picture = $result$picture;
          this.$result$picture.set({
            selectable: false,
          });
          this.$result$canvas.add(this.$result$picture);
          resolve();
        });
      });
    });
  }

  setShape(shape) {
    // console.log('setShape', shape);

    this.shape = shape.indexOf('<svg') === 0 ? 'data:image/svg+xml;utf8,' + shape : shape;

    if (this.$shape) {
      this.$canvas.remove(this.$shape);
      this.$shape = null;
    }

    if (this.$cover) {
      this.$canvas.remove(this.$cover);
      this.$cover = null;
    }

    if (this.$coverOverlay) {
      this.$canvas.remove(this.$coverOverlay);
      this.$coverOverlay = null;
    }

    if (this.$shapeOverlay) {
      this.$canvas.remove(this.$shapeOverlay);
      this.$shapeOverlay = null;
    }

    if (this.$result$shape) {
      this.$result$canvas.remove(this.$result$shape);
      this.$result$shape = null;
    }

    if (this.$result$shapeOverlay) {
      this.$result$canvas.remove(this.$result$shapeOverlay);
      this.$result$shapeOverlay = null;
    }

    const width = this.$canvas.width;
    const height = this.$canvas.height;

    return new Promise((resolve, reject) => {
      fabric.loadSVGFromURL(this.shape, (shapeSource, shapeOptions) => {
        shapeSource.forEach((item) => {
          item.fill = 'transparent';
        });

        const outline = shapeSource.find(
          (item) => item.id === this.outlineId || item.stroke === this.outlineStrokeColor,
        );
        if (outline) {
          outline.id = this.outlineId;
          outline.fill = 'white';
        }

        const midline = shapeSource.find(
          (item) => item.id === this.midlineId || (item.stroke === this.midlineStrokeColor && !item.strokeDashArray),
        );
        if (midline) {
          midline.id = this.midlineId;
        }

        const inline = shapeSource.find(
          (item) => item.id === this.inlineId || (item.stroke === this.midlineStrokeColor && !!item.strokeDashArray),
        );
        if (inline) {
          inline.id = this.inlineId;
        }

        const shapeRoot = shapeSource[0];

        let shapeMaxHeight = (2 / 3) * height;
        let shapeMaxWidth = (2 / 3) * width;

        let shapeHeight = shapeMaxHeight;
        let shapeWidth = shapeHeight * (shapeRoot.width / shapeRoot.height);

        if (shapeWidth > shapeMaxWidth) {
          shapeWidth = shapeMaxWidth;
          shapeHeight = shapeWidth * (shapeRoot.height / shapeRoot.width);
        }

        this.$shape = fabric.util.groupSVGElements(shapeSource, shapeOptions);

        this.$shape.set({
          scaleX: shapeWidth / shapeRoot.width,
          scaleY: shapeHeight / shapeRoot.height,
          originX: 'center',
          originY: 'center',
          left: width / 2,
          top: height / 2,
          selectable: false,
        });

        this.$shape.clone((shapeClipPath) => {
          this.$shapeClipPath = shapeClipPath;

          this.$shapeClipPath.set({
            scaleX: shapeWidth / shapeRoot.width,
            scaleY: shapeHeight / shapeRoot.height,
            originX: 'center',
            originY: 'center',
            left: 0,
            top: 0,
          });

          this.$cover = new fabric.Rect({
            width: width,
            height: height,
            originX: 'center',
            originY: 'center',
            left: width / 2,
            top: height / 2,
            fill: 'transparent',
            opacity: 0.01,
            selectable: false,
            evented: false,
          });

          // this.$shapeClipPath.inverted = true;
          this.$cover.clipPath = this.$shapeClipPath;

          this.$canvas.add(this.$cover);

          this.$coverOverlay = new fabric.Rect({
            width: width,
            height: height,
            originX: 'center',
            originY: 'center',
            left: width / 2,
            top: height / 2,
            fill: '#000000',
            opacity: 0.4,
            selectable: false,
            evented: false,
          });

          this.$shapeClipPath.inverted = true;
          this.$coverOverlay.clipPath = this.$shapeClipPath;

          this.$canvas.add(this.$coverOverlay);

          this.$shape.clone((shapeOverlay) => {
            this.$shapeOverlay = shapeOverlay;
            this.$shapeOverlay.opacity = 1;
            this.$shapeOverlay.fill = 'transparent';
            if (this.$shapeOverlay.getObjects) {
              this.$shapeOverlay.getObjects().forEach((item) => {
                if (item.fill === 'white' || /^#[Ff]{3,6}$/.test(item.fill)) {
                  item.fill = 'transparent';
                }
              });
            }
            this.$canvas.setOverlayImage(this.$shapeOverlay);

            this.$canvas.renderAll();

            this.$shape.clone(($result$shape) => {
              this.$result$shape = $result$shape;

              this.$shapeClipPath.clone(($result$shapeClipPath) => {
                this.$result$shapeClipPath = $result$shapeClipPath;

                this.$shapeOverlay.clone(($result$shapeOverlay) => {
                  this.$result$shapeOverlay = $result$shapeOverlay;

                  this.$result$canvas.clipPath = this.$result$shape;
                  // this.$result$canvas.clipPath = $result$shapeClipPath;

                  this.$result$canvas.add(this.$result$shapeOverlay);

                  this.render();

                  resolve();
                }, StickerEditor.CLONE_PROPERTIES);
              }, StickerEditor.CLONE_PROPERTIES);
            }, StickerEditor.CLONE_PROPERTIES);
          }, StickerEditor.CLONE_PROPERTIES);
        }, StickerEditor.CLONE_PROPERTIES);
      });
    });
  }

  scalePicture(value) {
    this.$picture.set({
      scaleX: this.pictureScale * value,
      scaleY: this.pictureScale * value,
    });
    this.$canvas.renderAll();
    this.render();
  }

  rotatePicture(angle) {
    this.$picture.set({
      angle,
    });
    this.$canvas.renderAll();
    this.render();
  }

  render() {
    // console.log('render');

    this.$result$picture.set({
      left: this.$picture.left,
      top: this.$picture.top,
      scaleX: this.$picture.scaleX,
      scaleY: this.$picture.scaleY,
      angle: this.$picture.angle,
    });

    this.$result$canvas.renderAll();

    this.changeCallback();
  }

  getPreviewSVG() {
    return this.$result$canvas.toSVG({});
  }

  getPreviewImage() {
    const dimensions = this.$result$shape.getBoundingRect();

    return this.$result$canvas.toDataURL({
      type: 'png',
      quality: 1,
      multiplier: 2,
      enableRetinaScaling: true,
      ...dimensions,
    });
  }

  getResultSVG() {
    return this.$result$canvas.toSVG({});
  }

  getResultImage() {
    this.$result$shapeOverlay.visible = false;

    this.$result$canvas.renderAll();

    const dimensions = this.$result$shape.getBoundingRect();

    const result = this.$result$canvas.toDataURL({
      type: 'png',
      quality: 1,
      multiplier: 2,
      enableRetinaScaling: true,
      ...dimensions,
    });

    this.$result$shapeOverlay.visible = true;

    this.$result$canvas.renderAll();

    return result;
  }

  getOutlineSVG() {
    return this.$result$canvas.toSVG({});
  }

  getOutlineImage() {
    this.$result$picture.visible = false;

    const outline = this.$result$shapeOverlay.getObjects().find((item) => item.id === this.outlineId);
    if (outline) {
      outline.visible = false;
    }

    const midline = this.$result$shapeOverlay.getObjects().find((item) => item.id === this.midlineId);
    if (midline) {
      midline.stroke = 'black';
    }

    const inline = this.$result$shapeOverlay.getObjects().find((item) => item.id === this.inlineId);
    if (inline) {
      inline.visible = false;
    }

    this.$result$canvas.renderAll();

    const dimensions = this.$result$shapeOverlay.getBoundingRect();

    const result = this.$result$canvas.toDataURL({
      type: 'png',
      quality: 1,
      multiplier: 2,
      enableRetinaScaling: true,
      ...dimensions,
    });

    this.$result$picture.visible = true;

    if (outline) {
      outline.visible = true;
    }

    if (midline) {
      midline.stroke = this.midlineStrokeColor;
    }

    if (inline) {
      inline.visible = true;
    }

    this.$result$canvas.renderAll();

    return result;
  }
};

/**
 * Usage
 */

/*

```javascript

const $container = document.querySelector('#sticker-editor-container'); // a simple div

const picture = 'http://exentriq-dpisticker.emiketic.com/static/media/picture-2.df2f944b.jpg';
const shape = '<svg width="40" height="40" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h40v40H0z"/></svg>';

const $editor = new StickerEditor($container, picture, shape);

$editor.initialize().then(() => {
  const changeCallback = () => {
    const previewImage = $editor.getPreviewImage();
    // ...
  };

  $editor.registerChangeCallback(changeCallback);

  $editor.setPicture(anotherPicture).then(() => {
    // ...
  })

  $editor.setShape(anotherShape).then(() => {
    // ...
  })

});

const previewImage = $editor.getPreviewImage();

const resultImage = $editor.getResultImage();

const outlineImage = $editor.getOutlineImage();
```
*/
