import React, { Component } from 'react';

import './dpisticker.css';

import './dpisticker';
// import { StickerEditor } from './dpisticker';
const StickerEditor = window.StickerEditor;

class DpistickerDemo extends Component {
  render() {
    return <div ref={(ref) => (this.$container = ref)} />;
  }

  componentDidMount() {
    window.$component = this;
    this.$editor = new StickerEditor(this.$container, this.props.picture, this.props.shape);

    this.$editor.initialize().then(() => {
      const callback = () => {
        this.props.onChange({
          preview: this.$editor.getPreviewImage(),
          result: this.$editor.getResultImage(),
          outline: this.$editor.getOutlineImage(),
        });
      };

      this.$editor.registerChangeCallback(callback);

      callback();
    });
  }

  componentWillUnmount() {
    this.$editor.unregisterChangeCallback();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.picture !== prevProps.picture) {
      this.$editor.setPicture(this.props.picture).then(() => this.$editor.setShape(this.props.shape));
    } else if (this.props.shape !== prevProps.shape) {
      this.$editor.setShape(this.props.shape);
    } else if (this.props.pictureScale !== prevProps.pictureScale) {
      this.$editor.scalePicture(this.props.pictureScale);
    } else if (this.props.pictureAngle !== prevProps.pictureAngle) {
      this.$editor.rotatePicture(this.props.pictureAngle);
    }
  }
}

export { DpistickerDemo };
