export const PICTURES = [
  {
    id: 'picture-0',
    uri: require('./assets/picture-0.jpg'),
  },
  {
    id: 'picture-1',
    uri: require('./assets/picture-1.jpg'),
  },
  {
    id: 'picture-2',
    uri: require('./assets/picture-2.jpg'),
  },
  {
    id: 'picture-3',
    uri: 'http://dpisticker.stage.exeq.me/static_exentriqdotcom/images/17dbd886-90d9-47a2-a861-b1ffad1da79e.jpg',
  },
  {
    id: 'picture-4',
    uri:
      'http://dpisticker.stage.exeq.me/static_exentriqdotcom/images/documents/DPISticker_86058/434a69e4-447d-431c-b6ed-d0b46f46402b.jpg',
  },
];
